<template>
  <q-page :style-fn="getAreaDimension">
    <div class="column table-render">
      <div
        ref="areaBreadCrumb"
        class="col-auto"
        style="z-index: 9"
      >
        <div class="column full-width">
          <div class="col-auto">
            <LayoutBreadcrumb>
              <div class="row no-wrap items-center">
                <div
                  v-if="labelBtnAdd"
                  class="col-auto text-right"
                >
                  <q-btn
                    unelevated
                    color="primary"
                    class="col-auto btnAdd"
                    padding="3px 12px"
                    @click="$emit('on-click-add')"
                  >
                    {{ labelBtnAdd }}
                  </q-btn>
                </div>

                <template v-if="$scopedSlots['bread-crumb-buttons']">
                  <slot name="bread-crumb-buttons" />
                </template>
              </div>
            </LayoutBreadcrumb>
          </div>
        </div>
      </div>

      <div class="col max-container-width q-mx-auto q-mt-xs">
        <div class="row">
          <div
            ref="qtablezerah"
            class="col q-pa-xs"
          >
            <div class="bg-white border-radius-4 border-box full-width full-height q-pa-xs">
              <q-table
                flat
                dense
                hide-bottom
                virtual-scroll
                :columns="columns"
                :pagination="pagination"
                :virtual-scroll-item-size="itemHeightSize"
                :virtual-scroll-sticky-size-start="itemHeightSize"
                class="my-sticky-virtscroll-table"
                row-key="id"
                separator="none"
                :table-colspan="colsCount"
                :table-style="`max-height: ${tableHeight}; height: ${tableHeight}; min-height: ${tableHeight}`"
                :data="state.rows"
                :loading="state.loading"
                :rows-per-page-options="[0]"
                @virtual-scroll="onScroll"
              >
                <template #top>
                  <div class="row full-width table-top items-center">
                    <div
                      v-if="state.count"
                      class="col-auto table-count-title"
                    >
                      <span v-if="state.count === 1">{{ `${state.count} ${rowsLabelSingular}` }} </span>
                      <span v-if="state.count > 1">{{ `${state.count} ${rowsLabelPlural}` }} </span>
                    </div>

                    <div
                      class="col"
                    />

                    <div
                      class="col-auto"
                      v-if="showExport"
                    >
                      <q-btn-dropdown
                        v-if="exporArchive"
                        flat
                        no-caps
                        color="primary"
                        padding="0 8px"
                        class="btn-hidden-dropdown-icon"
                      >
                        <template #label>
                          <div class="row items-center no-wrap">
                            <ExportIcon />
                            <div class="q-pl-sm btn-bold">
                              Exportar
                            </div>
                          </div>
                        </template>

                        <q-list
                          dense
                          separator
                        >
                          <q-item
                            disabled
                            clickable
                            v-close-popup
                          >
                            <q-item-section>
                              <a
                                href="#"
                                target="_self"
                                class="a-link"
                              >
                                Exportar CSV
                              </a>
                            </q-item-section>
                          </q-item>

                          <q-item
                            disabled
                            clickable
                            v-close-popup
                          >
                            <q-item-section>
                              <a
                                href="#"
                                target="_self"
                                class="a-link"
                              >
                                Exportar JSON
                              </a>
                            </q-item-section>
                          </q-item>
                        </q-list>
                      </q-btn-dropdown>
                    </div>

                    <div
                      class="col-3 q-px-sm"
                      v-if="showFilterText"
                    >
                      <FieldSearch
                        v-model="search"
                        placeholder="Busca Geral"
                      />
                    </div>

                    <div
                      class="col-auto"
                      v-if="config.filters.length"
                    >
                      <q-btn
                        dense
                        outline
                        color="grey-5"
                        class="col-auto"
                        padding="6px"
                        disable
                        @click="toggleFilter"
                      >
                        <FilterIcon />
                        <q-tooltip>
                          <span v-if="state.openFilters">Reduzir Filtros</span>
                          <span v-else>Filtros Avançados</span>
                        </q-tooltip>
                      </q-btn>
                    </div>
                  </div>
                </template>

                <template #header="props">
                  <template v-for="(cols, index) of getHeaders(props)">
                    <q-tr
                      :key="index"
                      :props="props"
                    >
                      <q-th
                        v-if="hasActions && !index"
                        auto-width
                        class="th-style-header"
                        :rowspan="getHeaders(props).length"
                      />

                      <template v-for="(col, indexCol) in cols">
                        <q-th
                          :key="col.name || indexCol"
                          :align="col.align"
                          :props="col.field ? props : undefined"
                          :class="col.class"
                          :width-auto="Boolean(col.widthAuto)"
                          :rowspan="col.rowSpan"
                          :colspan="col.colSpan"
                        >
                          {{ col.label }}
                        </q-th>
                      </template>
                    </q-tr>
                  </template>
                </template>

                <template
                  v-if="$scopedSlots['body']"
                  #body="props"
                >
                  <slot
                    name="body"
                    :props="prepareProps(props)"
                  />
                </template>

                <template
                  v-else
                  #body="props"
                >
                  <q-tr :props="prepareProps(props)">
                    <q-td
                      v-if="hasActions"
                      auto-width
                    >
                      <div class="row no-wrap items-center justify-center">
                        <template v-for="(action, actionIndex) in config.actions">
                          <div
                            class="col-auto"
                            :key="actionIndex"
                          >
                            <component
                              :is="action"
                              :model="props.row"
                            />
                          </div>
                        </template>
                      </div>
                    </q-td>

                    <template v-for="col in props.cols">
                      <q-td
                        :key="col.name"
                        :props="props"
                        :class="`${col.class || ''} ${props.row[`${col.name}Class`] || ''}`"
                        :width-auto="Boolean(col.widthAuto)"
                        v-if="props.row[`${col.name}Rowspan`] !== 0 && props.row[`${col.name}Colspan`] !== 0"
                        :rowspan="props.row[`${col.name}Rowspan`]"
                        :colspan="props.row[`${col.name}Colspan`]"
                      >
                        <component
                          :is="col.component"
                          v-if="col.component"
                          :row="props.row"
                          :col="col"
                          :expand="props.expand"
                          @on-toggle="props.expand = !props.expand"
                        />
                        <span
                          v-else
                          v-html="col.value"
                          style="line-height: 1.5em"
                        />
                      </q-td>
                    </template>
                  </q-tr>

                  <template v-if="props.expand && $scopedSlots['details']">
                    <q-tr
                      no-hover
                      :props="props"
                    >
                      <td colspan="100%">
                        <slot
                          name="details"
                          :props="props"
                        />
                      </td>
                    </q-tr>
                  </template>
                </template>
              </q-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </q-page>
</template>

<script>
import LayoutBreadcrumb from '@/components/layouts/LayoutBreadcrumb'
import ExportIcon from '@/assets/icons/ExportIcon.svg'
import { mapState, mapActions } from 'vuex'
import FilterIcon from '@/assets/icons/Filter.svg'
import FieldSearch from '@/components/forms/FieldSearch'

export default {
  components: {
    ExportIcon,
    FilterIcon,
    LayoutBreadcrumb,
    FieldSearch
  },

  props: {
    config: {
      required: true,
      type: Object
    },

    exporArchive: {
      type: Boolean,
      default: true
    },

    endpoint: {
      required: true,
      type: String
    },

    tableTitle: {
      required: true,
      type: String
    },

    labelBtnAdd: {
      type: String,
      default: null
    },

    itemHeightSize: {
      type: Number,
      default: 33
    },

    showFilterText: {
      type: Boolean,
      default: true
    },

    showExport: {
      type: Boolean,
      default: true
    },

    rowsLabelSingular: {
      type: String,
      default: 'registro encontrado'
    },

    rowsLabelPlural: {
      type: String,
      default: 'registros encontrados'
    },

    transformRows: {
      type: Function
    }
  },

  data () {
    const widthActions = 30 * (Array.isArray(this.config.actions) ? this.config.actions.length : 0) + 24
    const pagination = this.config && this.config.pagination ? { ...this.config.pagination } : {}

    return {
      search: '',
      pagination,
      tableHeight: '80vh',
      widthActions
    }
  },

  computed: {
    ...mapState({
      state ({ tableStore }) {
        const rows = tableStore.rows.map((row) => ({ ...row }))
        return { ...tableStore, rows: this.transformRows ? this.transformRows(rows) : rows }
      }
    }),

    stateQuery () {
      return this.state.query ?? {}
    },

    columns () {
      return this.config.columns.map((col) => ({
        ...col,
        field: col.name,
        headerClasses: col.headerClasses || 'th-style-header',
        sortable: [null, undefined, true].includes(col.sortable)
      }))
    },

    hasActions () {
      return Boolean(this.config?.actions?.length)
    },

    colsCount () {
      let count = this.config.columns.length
      if (this.hasActions) ++count
      return count
    },

    hasMultLineHeaders () {
      const headers = Array.isArray(this.config.headers) ? this.config.headers : []
      return Boolean(headers.find(({ headers }) => Array.isArray(headers) && headers.length))
    },

    routerName () {
      return this.$route.name
    }
  },

  mounted () {
    this.loadQueryRoute()
  },

  beforeDestroy () {
    this.clearStore()
  },

  methods: {
    ...mapActions({
      clearStore: 'tableStore/clear',
      setQuery: 'tableStore/setQuery',
      requestList: 'tableStore/requestList'
    }),

    loadQueryRoute () {
      this.clearStore()

      this.$nextTick().then(() => {
        const query = toQueryString(this.$route.query)
        query.page = 1
        query.limit = 50
        this.setQuery(query)
      })
    },

    getClass (classes) {
      const list = classes ? Array.isArray(classes) ? [...classes] : [classes] : []
      const usesDefault = !list.includes('th-style-info') && !list.includes('th-style-header') && !list.includes('th-style-subheader')
      return usesDefault ? [...list, 'th-style-info'] : list
    },

    prepareProps (props) {
      if (!props.__trClass) props.__trClass = ''
      if (props.__trClass.includes('tr-odd')) return props
      if (props.__trClass.includes('tr-even')) return props
      let oddEvenClass = `${(props.rowIndex + 1) % 2 ? 'tr-odd' : 'tr-even'}`
      if ([true, false].includes(props.row.isOdd)) oddEvenClass = props.row.isOdd ? 'tr-odd' : 'tr-even'
      props.__trClass = `${props.__trClass} ${oddEvenClass}`
      return props
    },

    getHeaders (props) {
      const headers = Array.isArray(this.config.headers) ? this.config.headers : []
      if (!headers.length) return [props.cols]

      const lines = []
      const hasSubHeaders = (subHeaders) => Array.isArray(subHeaders) && subHeaders.length

      const makeLine = (headers, level) => {
        if (lines.length < level) lines[level - 1] = []
        const lenCols = lines[level - 1].length
        let headerColSpan = 0

        const cols = headers.map((header, index) => {
          if (header.column) {
            ++headerColSpan
            const col = { ...props.colsMap[header.column] }
            if (header.align) col.align = header.align
            if (header.rowSpan) col.rowSpan = header.rowSpan
            if (header.colSpan) col.colSpan = header.colSpan
            if (header.class) col.class = `${col.class || ''} ${header.class || ''}`.trim()
            return col
          }

          const colSpan = hasSubHeaders(header.headers) ? makeLine(header.headers, level + 1) : 0
          headerColSpan += colSpan

          return {
            name: `subheader-${level}-${lenCols + index}`,
            label: header.label,
            align: header.align,
            class: header.class,
            colSpan: colSpan,
            rowSpan: header.rowSpan
          }
        })

        lines[level - 1].push(...cols)
        return headerColSpan
      }

      makeLine(headers, 1)

      return lines
    },

    async getResults () {
      if (this.state.loading) return
      this.requestList(this.endpoint)
      const routeQuery = toQueryString(this.$route.query)
      const stateQuery = toQueryString(this.stateQuery)
      if (isEqual(routeQuery, stateQuery)) return
      this.$router.push({ query: stateQuery }).catch(() => null)
    },

    toggleFilter () {
      this.$store.dispatch(
        'tableStore/toggleFilters',
        this.config.filters
      )
    },

    getAreaDimension (offset, height) {
      this.setTableHeight(offset ? height - offset - 12 : 400)
      return { minHeight: offset ? `calc(100vh - ${offset}px)` : '100vh' }
    },

    setTableHeight (tableHeight) {
      this.$nextTick().then(() => {
        const { clientHeight: areaBreadCrumbHeight } = this.$refs.areaBreadCrumb
        const [{ clientHeight: topTableHeight }] = this.$refs.qtablezerah.getElementsByClassName('q-table__top')
        this.tableHeight = `${tableHeight - areaBreadCrumbHeight - topTableHeight - 16}px`
      })
    },

    onScroll ({ direction, index, to }) {
      if (index <= 1) return
      if (direction === 'decrease') return

      const { error, loading } = this.state
      if (error || loading) return

      const { loaded, hasMore } = this.state
      if (!loaded || !hasMore) return

      const { limit } = this.stateQuery
      const ignoreLoadNext = (index + limit) <= to
      if (ignoreLoadNext) return

      const { page, ...query } = { ...this.stateQuery }
      query.page = page + 1

      this.setQuery(query)
    }
  },

  watch: {
    search (search) {
      if (!this.showFilterText) return
      const arg = String(search || '').trim()
      if (isEqual(this.stateQuery.$, arg)) return
      const query = { ...this.stateQuery, page: 1, $: arg }
      this.setQuery(query)
    },

    routerName () {
      this.loadQueryRoute()
    },

    stateQuery: {
      deep: true,
      handler (newQuery, oldQuery) {
        if (isEqual(newQuery, oldQuery)) return
        this.getResults()
      }
    }
  }
}

const isEqual = (target, source) => JSON.stringify(target) === JSON.stringify(source)

const clearQuery = (query) => {
  if (!query) return {}
  return Object.keys(query).sort().reduce((params, key) => {
    if ([null, undefined].includes(query[key])) return params
    if (Array.isArray(query[key]) && !query[key].length) return params
    if (typeof query[key] === 'string' && !query[key].trim()) return params
    if (typeof query[key] === 'function') return params
    if (Array.isArray(query[key])) query[key] = [...query[key]].sort().join(',').trim()
    return { ...params, [key]: query[key] }
  }, {})
}

const toQueryString = (query) => {
  const queryString = Object.keys(clearQuery(query)).reduce((params, key) => ({ ...params, [key]: String(query[key]).trim() }), {})
  delete queryString.page
  // delete queryString.limit
  return queryString
}

</script>

<style lang="sass">
  .table-render
    .my-sticky-virtscroll-table.q-table__card
      border-top-left-radius: 4px
      border-top-right-radius: 4px

    .my-sticky-virtscroll-table
      background-color: #fff

    .text-summary
      font-style: normal
      font-weight: 600
      font-size: 0.8em
      line-height: 16px
      letter-spacing: 0.37px
      color: #263238
      opacity: 0.5

    thead>tr>*
      position: sticky
      opacity: 1
      z-index: 1
      background: black

    thead>tr>*
      top: 0

    .btnAdd
      font-style: normal
      font-weight: normal
      font-weight: 600
      font-size: 0.8em

    .table-title
      font-style: normal
      font-weight: 600
      font-size: 18px
      color: #263238

    .table-count-title
      font-style: normal
      font-weight: normal
      font-size: 1.2em
      color: rgb(38, 50, 56, 0.64)

    .q-table__top.relative-position.row
      padding: 8px 2px 8px 8px
      border-top-left-radius: 4px
      border-top-right-radius: 4px

    .q-table__top, .q-table__bottom, thead tr:first-child th
      background-color: #fff

    .q-table tbody>tr>td
      font-style: normal
      font-size: 1em
      line-height: 14px
      padding: 8px 12px
      color: #263238

    .q-table thead>tr.tr-even>td
      background-color: rgb(238, 238, 238, 0.8)

    .q-table tbody>tr.tr-even>td
      background-color: rgb(238, 238, 238, 0.8)

    .q-table tbody>tr>td.border-top-even
      border-top: 1px solid white

    .q-table tbody>tr>td.border-top-odd
      border-top: 1px solid rgb(238, 238, 238, 0.8)

    .q-table thead>tr>th
      font-style: normal
      font-size: 1em
      line-height: 14px
      border-right: 1px solid white

      &.th-style-info
        font-weight: normal
        color: rgb(38, 50, 56, 0.8)
        background-color: rgba(238, 238, 238, 1)
        padding: 8px 12px

      &.th-style-subheader
        font-weight: 600
        color: rgb(38, 50, 56, 0.8)
        background-color: rgba(238, 238, 238, 1)
        border-bottom: 1px solid white
        padding: 8px 12px

      &.th-style-header
        font-weight: 600
        color: #263238
        background-color: #E1EBF4
        border-bottom: 1px solid white
        padding: 8px 12px

      &.sortable
        i
          display: none
      &.sortable.sorted
        i
          display: inline-flex

    .q-table thead>tr>th.border-right-none
      border-right: 0

    tbody > tr.hover-action > td
      font-style: normal
      font-weight: normal
      font-size: 0.8em
      line-height: 12px
      color: #263238

    tbody>tr.hover-action>td:last-child
      padding: 0 16px 0 8px

    .btn-bold
      font-weight: 600
      color: #0094D9

    .btn-hidden-dropdown-icon
      i.q-btn-dropdown__arrow
        display: none

  .a-link
    color: #0094D9
    text-decoration: none
</style>
