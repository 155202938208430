import FieldText from '@/components/forms/FieldText'
import moment from '@/components/moment'
import BtnView from '../components/BtnView'

export default {
  actions: [
    BtnView
  ],

  pagination: {
    query: {}
  },

  columns: [{
    name: 'name',
    label: 'Órgão Emissor',
    align: 'left',
    widthAuto: true
  }, {
    name: 'type',
    label: 'Tipo',
    align: 'left',
    widthAuto: true
  }, {
    name: 'updatedAt',
    label: 'Data Atualização',
    class: 'col-max-400',
    align: 'center',
    format: (updatedAt) => {
      return moment(updatedAt).format('DD/MM/YY')
    }
  }],

  filters: [{
    name: 'name',
    label: 'Orgão Emissor',
    debounce: 350,
    component: FieldText
  }]
}
