<template>
  <TableRender
    show-filter-text
    :config="config"
    :show-export="false"
    endpoint="/agencies"
    table-title="Orgãos Emissores"
    :label-btn-add="isAdmin ? 'Cadastrar' : null"
    @on-click-add="onClickAdd"
  />
</template>

<script>
import config from '../config/list'
import TableRender from '@/components/table/TableRender'
import { ROUTE_AGENCY_CREATE } from '../constants'
import { mapGetters } from 'vuex'

export default {
  components: {
    TableRender
  },

  data () {
    return { config }
  },

  mounted () {
    const vm = this
    const isGeneralRole = vm.$store.getters.isGeneralRole
    if (!isGeneralRole) return vm.$router.go(-1)
  },

  computed: {
    ...mapGetters({
      isAdmin: 'isAdmin'
    })
  },

  methods: {
    onClickAdd () {
      this.$router.push({ name: ROUTE_AGENCY_CREATE })
    }
  }
}

</script>
